import React from 'react';

function Footer() {
return (
    <footer className="bg-dark text-center text-white">
            <div className="text-center p-3">
                    &copy; 2023 bahi12.com  |  All Rights Reserved  |  Developed by Hassan EL BAHI | <a href="/privacy" className="text-white">Privacy Policy</a>
            </div>
    </footer>
);
};
export default Footer;
