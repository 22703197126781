import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, CardBody, CardTitle, Container } from 'reactstrap';
import UnitList from './UnitList';
import ClassList from '../components/ClassList';
import postedOn from '../utils/FormatDate';
import { useParams } from 'react-router-dom';
import Loading from './Loading';

function PostList() {
  const [posts, setPosts] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const { classSlug, unitSlug } = useParams();
  const [loading, setLoading] = useState(true);  // Initially set loading to true
  const [error, setError] = useState(null);
  const API = process.env.REACT_APP_API_URL;

  const fetchPosts = async (url) => {
    setLoading(true);  // Set loading to true when fetching starts
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Error fetching posts');
      }
      const data = await response.json();

      // If data contains a results key (pagination), use it
      const normalizedPosts = Array.isArray(data) ? data : data.results || [];

      setPosts(normalizedPosts);
      setNextPage(data.next || null);
      setPrevPage(data.previous || null);
    } catch (error) {
      console.log('Error fetching posts:', error);
      setError(error);
    } finally {
      setLoading(false);  // Set loading to false after data fetch completes
    }
  };

  useEffect(() => {
    let url = `${API}/`;
    if (classSlug && unitSlug) {
      url = `${API}/class/${classSlug}/unit/${unitSlug}/posts/`;
    } else if (classSlug) {
      url = `${API}/class/${classSlug}/posts/`;
    }

    fetchPosts(url);
  }, [API, classSlug, unitSlug]);

  const handleNextPage = () => {
    if (nextPage) {
      setLoading(true);
      fetchPosts(nextPage);
    }
  };

  const handlePrevPage = () => {
    if (prevPage) {
      setLoading(true);
      fetchPosts(prevPage);
    }
  };

  const renderPosts = () => {
    if (loading) {
      return <div className="w-100"><Loading /></div>;
    }

    if (error) {
      return <p>Error: {error.message}</p>;
    }

    if (posts.length === 0) {
      return <p>No posts found</p>;
    }

    return (
      <Row>
        <ClassList />
        {classSlug && <UnitList />}
        {posts.map(post => (
          <Col key={post.slug} xs={12} md={6} lg={4} className="mb-4">
            <Card className="shadow-sm">
              <CardBody>
                <Row>
                  <Col xs={4} className="d-flex align-items-center">
                    {post.skill && post.skill.image && (
                      <img
                        src={post.skill.image}
                        alt={post.skill.name}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'contain',
                          transform: 'rotate(-90deg)',
                        }}
                        className="rounded"
                      />
                    )}
                  </Col>
                  <Col xs={8}>
                    <CardTitle className="h5 font-weight-bold">{post.title}</CardTitle>
                    {post.published && (
                      <p>
                        <small className="text-muted">{postedOn(post.published)}</small>
                      </p>
                    )}
                    <a
                      href={`/${post.class_field.slug}/${post.unit ? `${post.unit.slug}/` : ''}${post.skill.slug}/${post.slug}`}
                      className="text-primary text-decoration-none"
                    >
                      Read More
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Container>
      {renderPosts()}
      <div className="d-flex justify-content-between my-4">
        {prevPage && <Button onClick={handlePrevPage}>Previous</Button>}
        {nextPage && <Button onClick={handleNextPage}>Next</Button>}
      </div>
    </Container>
  );
}

export default PostList;
