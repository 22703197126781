import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './template/Layout';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import Register from './components/auth/Register';
import Profile from './components/Profile';
import PostDetail from './components/PostDetail';
import PostList from './components/PostList';
import ClassList from './components/ClassList';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from './redux/actions';
import About from './components/About';
import Contact from './components/Contact';
// import Maintenance from './components/Maintenance';
import DictionarySearch from './components/DictionarySearch';
import GalleryList from './components/GalleryList';
import GalleryPhotos from './components/GalleryPhotos';
import Search from './components/Search';
//import app css
import './App.css';

import ReactGa from 'react-ga4';
import Dashboard from './components/Dashboard';
import PrivacyPolicy from './components/Privacy';

ReactGa.initialize('G-NJGSQ5V5EJ');





function App() {

  const dispatch = useDispatch();
  // const location = useLocation();

// Site title
  useEffect(() => {
    document.title = "HelB Portal"
  })
  // Track your authentication
  useEffect(() => {
      if (localStorage.getItem('auth_token')) {
          dispatch(fetchUser());
      }
  }, [dispatch]);

  // Google Analytics

  useEffect(() => {
    ReactGa.send({ hitType: "pageview", page: window.location.pathname });
  }, []);




  return (
    <Layout>
      <Routes>
        <Route path="/" element={<PostList />} />
        {/* <Route path="/" element={<Maintenance />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/me" element={<Profile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dictionary" element={<DictionarySearch />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/:classSlug/:unitSlug?/:skillSlug/:postSlug" element={<PostDetail />} />
        <Route path="/classes" element={<ClassList />} />
        <Route path="/class/:classSlug/posts" element={<PostList />} />
        <Route path="/class/:classSlug/unit/:unitSlug/posts" element={<PostList />} />
        <Route path="/galleries" element={<GalleryList />} />
        <Route path="/gallery/:galleryId/photos" element={<GalleryPhotos />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/search" element={<Search showSearchInput={false} isNavbar={true}/>} />
        <Route path="/search/:searchQuery" element={<Search />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

function NotFound() {
  return <h1>404 - Page Not Found</h1>;
}

export default App;