import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
 

      <h2>1. Introduction</h2>
      <p>
        Welcome to HelB Portal. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        <strong>Personal Information:</strong> We may collect personal information such as your name, email address, and any other information you voluntarily provide to us.
      </p>
      <p>
        <strong>Usage Data:</strong> We collect information about how you use our website, including your IP address, browser type, pages visited, and the time and date of your visit.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Respond to your inquiries and communicate with you.</li>
        <li>Analyze website usage and improve our website's functionality.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>4. Cookies</h2>
      <p>
        We use cookies to enhance your experience on our website. Cookies are small data files stored on your device that help us understand how you interact with our site. You can manage your cookie preferences through your browser settings.
      </p>

      <h2>5. Third-Party Services</h2>
      <p>
        We may use third-party services to help us operate our website and provide our services. These third parties may have access to your personal information but are obligated to protect it and use it only for the purposes we specify.
      </p>

      <h2>6. Data Security</h2>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no internet transmission is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>7. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and update your personal information.</li>
        <li>Request the deletion of your personal information.</li>
        <li>Opt-out of receiving marketing communications.</li>
      </ul>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please <a href="/contact">contact us</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
